const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export default {
  computed: {
    // ...mapState(useBaseStore, {
    //   hasInit: 'hasInit',
    //   // currency: 'currency',
    //   symbol: 'symbol',
    //   globalDateFormat: 'dateFormat',
    // }),
    // ...mapWritableState(useSearchStore, ['filters']),
    hasInit() {
      return this.$store.base.hasInit;
    },
    isIdle() {
      return this.$store.base.isIdle;
    },
    isSessionVerified() {
      return this.$store.auth.getVerified;
    },
    isAuthed() {
      return this.$store.auth.isAuthed;
    },
    user() {
      return this.$store.user.user || null;
    },
    isBusiness() {
      return this.user?.business || this.user?.accountType === 2 || this.user?.businessId;
    },
    subscription() {
      return this.$store.user.subscription || null;
    },
    country() {
      return this.$store.base.country || 'us';
    },
    currency() {
      return this.$store.base.currency || 'usd';
    },
    currencySymbol() {
      return this.$store.base.currencySymbol || '$';
    },
    isMobile() {
      return this.$mq.xs || this.$mq.sm || this.$device.isMobile;
    },
    isDesktop() {
      return !this.isMobile && (this.$device.isDesktop || !this.$mq.sm);
    },
  },
  methods: {
    async detectTimezone() {
      const jstz = await import('jstimezonedetect').then(e => e.default || e);
      const d = jstz.determine();
      const n = d.name();
      if (`${n}`.includes('America')) {
        this.$store.base.SetCountry('US');
      } else if (`${n}`.includes('Australia')) {
        this.$store.base.SetCountry('AU');
      } else if (`${n}`.includes('Europe/London')) {
        this.$store.base.SetCountry('GB');
      } else if (`${n}`.includes('Europe')) {
        this.$store.base.SetCountry('EU');
      }
    },
    async initApp(thinking = true) {
      if (this.$store.base.hasInit) {
        if (this.$store.base.isThinking && thinking) {
          this.$store.base.SetThinking(false);
        }
        return delay(1);
      }

      if (this.country === 'us') {
        this.detectTimezone();
      }

      if (this.isAuthed) {
        const res = await this.$store.user.GetUser();
        if (res.error) {
          this.$store.user.Logout();
        }
      }

      await this.$store.base.init();

      return delay(1);
    },
    $delay: delay,
    $alert(params = {}) {
      const { message, cta = null, title = null, onConfirm = null } = params || {};

      if (!message) {
        throw new Error('message_required');
      }

      this.$mitt.emit('alert', {
        message,
        cta,
        title,
        onConfirm: onConfirm || null,
      })
    },
    Toast(type, text, opts = {}) {
      if (type === 'hide' || !text) {
        return;
      }
      
      if (!opts.duration) {
        opts.duration = 5000;
      }

      if (!opts.action) {
        opts.close = true;
      }
      // Toastify({
      //   text: "This is a toast",
      //   duration: 3000,
      //   destination: "https://github.com/apvarun/toastify-js",
      //   newWindow: true,
      //   close: true,
      //   gravity: "top", // `top` or `bottom`
      //   position: "left", // `left`, `center` or `right`
      //   stopOnFocus: true, // Prevents dismissing of toast on hover
      //   style: {
      //     background: "linear-gradient(to right, #00b09b, #96c93d)",
      //   },
      //   onClick: function(){} // Callback after click
      // }).showToast();
      const toast = {
        text,
        gravity: this.isMobile ? 'bottom' : 'top',
        position: 'center',
        className: ['g-toastify', type === 'error' ? 'g-toastify-error' : 'g-toastify-success'].join(' '),
        avatar: type === 'error' ? 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEoSURBVHgB7ZRtDcJADIa7BAFzwOFgDpgEHAwH4AAcIAEcIGFBAaBgEoaD0pH3sstld/te+HFP0oxdm/Vtr5QoEAiMgJmVWExLg8Q511S/FS2FJLsj8RX2E0FLIIn2OrlxdsFZRnOC1hcwZZzHYiVsvpkw2p3h/a5bL88dfBeaAyOB2fpfN4z3HDEpTQ2SlVbrbQEKMcWkVyEfO6OyQ4Oowjo7IvZEU4Cq2E7kEoBzfRUJjQVJmBsWjUdAMsluaGsndsLB4dO74UgDkytXhZaAzOGLmwa3j4Cr+Z9v8Kdck7TE9N8NUJ57/LrCwlchBrJ0+Vc0kCiKPvLYdAyPhwh4iaXYfA8axrb6htiN+oIhfPJ4ct8VRR2EVAO2Jk8bPbzlql4UCAT+mS+h8e/cgFgX1QAAAABJRU5ErkJggg==' : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAAXNSR0IArs4c6QAAAJ9QTFRFAAAAML+AIL+AKL+HKL+ALcKGKMKGLL+DKL+DK8GEKMGEKr+FKL+FLMGFKcGFKcKEKb+EKsGFKsGDK8KEK8GEK8GDK8KFK8KEKsCFKsCEKsKFKsCFKcCFK8KEKcKEKcCEK8GEKsGEKsCEKsGFKsGEKsCFK8GEKsGE////8vv45ffw5PfvyvDgleDCUs2bUsycUsybRMiTN8WMN8WLKsGE+frkgwAAACh0Uk5TABAQICA/P0BAX19gYG9vcHB/gI+PkJ+fn5+vr6+/v7/Pz8/f39/v7ys+9/MAAAErSURBVHja7dPdUsIwEIbhj4CAf5VqUQFRamuoIKLw3f+1Oc7QEafd7JbjPufvTtJs0Wq1NIPraeZJn07jIRpzt55H8odes3zMiiYjbjxr5OcwGlNwBws3pyh10KUMeDaf/+RbXFIxQlA3o2LpEHLPEP0SXRq40w7wueNBDFlGyaZY8WAJ0SDQF2uWhpDEof6bpQiSqannEyQLU88cEh/u9a/IPzu5Jw0DVsVG7mm4wntRbMR+adij7e8EoWeqPmM5odJrzxjx/4SP2p6xusrlhPqefdgWYbuu73PA+DPsv/asMYGsQ4MeAhKqJgjpeCryHoJGVFxAkTAogWrOgBS6zoKiFweLRDm/7ipjDR/BrPvIisRBIIzIeMTHQh5yFs9ePenfZlEfrVZL8QN1r/iHeMjLswAAAABJRU5ErkJggg==',
        // style: {
        //   background: type === 'error' ? '#FC5A5A' : '',
        // },
        ...opts,
      }
      return this.$toast(toast).showToast();
    },
  },
};
