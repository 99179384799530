import { defineStore } from 'pinia';

import planData from '~/data/plans.json';

import { useAuthStore } from './auth';

const euCodes = ['AL', 'AD', 'AT', 'AZ', 'BY', 'BE', 'BA', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'GE', 'DE', 'GR', 'HU', 'IS', 'IE', 'IT', 'KZ', 'XK', 'LV', 'LI', 'LT', 'LU', 'MK', 'MT', 'MD', 'MC', 'ME', 'NL', 'NO', 'PL', 'PT', 'RO', 'RU', 'SM', 'RS', 'SK', 'SI', 'ES', 'SE', 'CH', 'TR', 'UA', 'GB', 'VA'];

export const useBaseStore = defineStore('base', {
  id: 'base',
  state: () => {
    return {
      _country: 'us',
      _region: 'us',
      _init: false,
      _thinking: false,
      _idle: false,
    }
  },
  
  getters: {
    hasInit({ _init = false }) {
      return _init;
    },
    isThinking({ _thinking = false }) {
      return !!_thinking;
    },
    isIdle({ _idle = false }) {
      return !!_idle;
    },
    currency({ _country = 'us' }) {
      if (_country === 'us') {
        return 'usd';
      } else if (_country === 'eu') {
        return 'eur';
      } else if (_country === 'gb') {
        return 'gbp';
      } else if (euCodes.includes(`${_country}`.toUpperCase())) {
        return 'eur';
      }
      return 'usd';
    },
    currencySymbol() {
      if (this.currency === 'eur') {
        return '€';
      } else if (this.currency === 'gbp') {
        return '£';
      }
      return '$';
    },
    country({ _country = 'us' }) {
      return _country;
    },
    region({ _region = 'us' }) {
      return _region;
    },
    getPlanData() {
      return (planId) => {
        let plan = planData.find(r => r.planId === planId) || null;
        if (!plan) {
        // enterprise-studio-monthly-v2
          plan = planData.find(r => r.terms.month.id === planId || r.terms.year.id === planId)
        }
        return plan;
      }
    }
  },

  actions: {
    SetCountry(country) {
      this._country = `${country}`.toLowerCase();
    },
    SetThinking() {

    },
    async init() {
      const auth = useAuthStore();
      if (auth.isAuthed) {
        this._init = true;
        return Promise.resolve();
      }
      const res = await fetch('/api/auth', {
        credentials: 'include'
      });
      try {
        const state = await res.json();
        // console.log('state', state);
        if (state && state.session) {
          auth.HandleStateUpdate({ state });
        }
      } catch (e) {}
      this._init = true;
    }
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBaseStore, import.meta.hot))
}