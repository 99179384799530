import { useMq } from 'vue3-mq';

export default defineNuxtPlugin(() => {
  const mq = useMq();

  return {
    provide: {
      mq
    }
  }
});