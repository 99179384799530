import { defineStore } from 'pinia';

import { useAuthStore } from './auth';

export const useUserStore = defineStore('user', {
  id: 'user',
  state: () => {
    return {
      _user: null,
      _member: null,
      _business: null, // slim version
      _settings: {},
      _payment: null,
      _lastSync: 0,
    }
  },
  
  getters: {
    user({ _user = null }) {
      return _user;
    },
    business({ _business = null }) {
      return _business;
    },
    member({ _member = null }) {
      return _member;
    },
    subscription({ _user = null }) {
      return _user?.subscription || null;
    },
    hasSub({ subscription }) {
      return subscription && subscription.planId;
    },
    hasAccess({ subscription = null }) {
      return subscription && subscription.planId;
    },
    settings({ _settings }) {
      return _settings;
    },
    payment({ _payment = null }) {
      return _payment;
    },
  },

  actions: {
    SetUser(user = null) {
      this._user = user;
    },
    GetUserCollections(params = {}) {
      const token = this.getAuthToken();
      const req = new this.$api.CollectionServiceController({ ...this.$config, client: true, token });
      return req.GetUserCollections(params).catch(error => ({ error }));
    },
    MoveUserCollection(params = {}) {
      const token = this.getAuthToken();
      const req = new this.$api.CollectionServiceController({ ...this.$config, client: true, token });
      return req.MoveUserCollection(params).catch(error => ({ error }));
    },
    UpdateUserCollection(params = {}) {
      const token = this.getAuthToken();
      const req = new this.$api.CollectionServiceController({ ...this.$config, client: true, token });
      return req.UpdateUserCollection(params).catch(error => ({ error }));
    },
    RemoveUserCollection(params = {}) {
      const token = this.getAuthToken();
      const req = new this.$api.CollectionServiceController({ ...this.$config, client: true, token });
      return req.RemoveUserCollection(params).catch(error => ({ error }));
    },
    ClearUserCollectionItems(params = {}) {
      const token = this.getAuthToken();
      const req = new this.$api.CollectionServiceController({ ...this.$config, client: true, token });
      return req.ClearUserCollectionItems(params).catch(error => ({ error }));
    },
    CreateUserCollection(params = {}) {
      // const { name, color } = params || {};
      const token = this.getAuthToken();
      const req = new this.$api.CollectionServiceController({ ...this.$config, client: true, token });
      return req.CreateUserCollection(params).catch(error => ({ error }));
    },
    SetDefaultUserCollection(params = {}) {
      const { guid } = params || {};
      const token = this.getAuthToken();
      const req = new this.$api.CollectionServiceController({ ...this.$config, client: true, token });
      return req.SetDefaultUserCollection({ guid }).catch(error => ({ error }));
    },
    GetUserInvoices(params = {}) {
      const token = this.getAuthToken();
      const req = new this.$api.UserServiceController({ ...this.$config, client: true, token });
      return req.GetUserInvoices(params).catch(error => ({ error }));
    },
    PostUserUpdateDetails(update = {}) {
      const token = this.getAuthToken();
      const req = new this.$api.UserServiceController({ ...this.$config, client: true, token });
      return req.PostUserUpdateDetails({ update }).then((data) => {
        const { user } = data;
        if (user) {
          this._user = user;
        }
        return data;
      }).catch(error => ({ error }));
    },
    PostUserUpdatePassword(params = {}) {
      const token = this.getAuthToken();
      const req = new this.$api.UserServiceController({ ...this.$config, client: true, token });
      return req.PostUserUpdatePassword(params).then((data) => {
        const { user } = data;
        if (user) {
          this._user = user;
        }
        return data;
      }).catch(error => ({ error }));
    },
    PostUserSetupPassword(params = {}) {
      const token = this.getAuthToken();
      const req = new this.$api.UserServiceController({ ...this.$config, client: true, token });
      return req.PostUserSetupPassword(params).then((data) => {
        const { user } = data;
        if (user) {
          this._user = user;
        }
        return data;
      }).catch(error => ({ error }));
    },
    PostUserUpdateEmail(params = {}) {
      const token = this.getAuthToken();
      const req = new this.$api.UserServiceController({ ...this.$config, client: true, token });
      return req.PostUserUpdateEmail(params).then((data) => {
        const { user } = data;
        if (user) {
          this._user = user;
        }
        return data;
      }).catch(error => ({ error }));
    },
    GetUser() {
      const token = this.getAuthToken();
      const req = new this.$api.UserServiceController({ ...this.$config, client: true, token });
      return req.GetUser().then((data) => {
        const { user, business = null } = data;
        
        this._user = user;
        this._business = business;
        return { user };
      }).catch((error) => {
        const msg = error.message || error;
        if (`${msg}`.includes('401') || `${msg}`.includes('Unauthorized')) {
          console.error('Unauthorized');
          try {
            this.Logout();
          } catch (e) {
            console.error(e);
          }
        } else {
          console.error(error);
        }
        return { error };
      });
    },
    CreateCheckoutSession({ planId, promo, currency, academySeats = 0, metadata, utm = null }) {
      const token = this.getAuthToken();
      const req = new this.$api.StripeServiceController({ ...this.$config, client: true, token });
      return req.CreateCheckoutSession({ planId, promo, currency, academySeats, metadata, utm }).catch(error => ({ error }));
    },
    GetPayPalCustomerToken(intentId) {
      const token = this.getAuthToken();
      const req = new this.$api.PaypalServiceController({ ...this.$config, client: true, token });
      return req.GetPayPalCustomerToken({ intentId }).catch(error => ({ error }));
    },
    SetupPayPalSubscription(params) {
      const token = this.getAuthToken();
      const req = new this.$api.PaypalServiceController({ ...this.$config, client: true, token });
      return req.SetupPayPalSubscription(params).catch(error => ({ error }));
    },
    SetupPayPalPurchase(params) {
      const token = this.getAuthToken();
      const req = new this.$api.PaypalServiceController({ ...this.$config, client: true, token });
      return req.SetupPayPalPurchase(params).catch(error => ({ error }));
    },
    GetUserCreditReport(params) {
      const token = this.getAuthToken();
      const req = new this.$api.UserServiceController({ ...this.$config, client: true, token });
      return req.GetUserCreditReport(params).catch(error => ({ error }));
    },
    GetUserAllDownloads(params) {
      const token = this.getAuthToken();
      const req = new this.$api.UserServiceController({ ...this.$config, client: true, token });
      return req.GetUserAllDownloads(params).catch(error => ({ error }));
    },
    CancelUserSubscription(params) {
      const token = this.getAuthToken();
      const req = new this.$api.UserServiceController({ ...this.$config, client: true, token });
      return req.CancelUserSubscription(params).then((data) => {
        const { user } = data;
        if (user) {
          this._user = user;
        }
        return data;
      }).catch(error => ({ error }));
    },
    ValidateCheckoutSession(sessionId) {
      const token = this.getAuthToken();
      const req = new this.$api.StripeServiceController({ ...this.$config, client: true, token });
      return req.ValidateCheckoutSession({ sessionId }).catch(error => ({ error }));
    },
    PurchaseCreditPack(params = {}) {
      const token = this.getAuthToken();
      const req = new this.$api.StripeServiceController({ ...this.$config, client: true, token });
      return req.PurchaseCreditPack(params).catch(error => ({ error }));
    },
    UploadAvatar({ filename = null, filesize = 0, mimetype = 'image/jpeg', ext = 'jpg' }) {
      const token = this.getAuthToken();
      const req = new this.$api.UploadServiceController({ ...this.$config, client: true, token });
      return req.NewUploadFile({ upload: { filename, filesize, mimetype, ext, type: 'avatar', isPublic: true } }).catch((e) => {
        console.error(e);
        return null;
      });
    },

    async Logout() {
      const auth = useAuthStore();
      await auth.Logout();
      // const token = this.getAuthToken();
      // const req = new this.$api.UserServiceController({ ...this.$config, client: true, token });
      // await req.Logout().then(() => {
      //   try {
      //     const auth = useAuthStore();
      //     auth.$reset();
      //   } catch (e) {}
      //   this.$reset();
      // }).catch(() => null);
    }
  },

  hydrate(_state, _initialState) {
    // console.log('hydrate', _state._user, _initialState._user)
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}