import mitt from 'mitt'

export default defineNuxtPlugin(() => {
  const emitter = mitt()

  return {
    provide: {
      mitt: {
        emit: emitter.emit,
        off: emitter.off,
        on: emitter.on,
      }
    }
  }
})