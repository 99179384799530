export default defineNuxtPlugin(async () => {
  const auth = await import('@mavrick/mp-auth').then(e => e.default || e);
  const api = await import('@mavrick/mp-api').then(e => e.default || e);
  const gallery = await import('@mavrick/mp-gallery').then(e => e.default || e);

  const { AuthServiceController } = auth;
  const { UserServiceController, StripeServiceController, PaypalServiceController, UploadServiceController } = api;
  const { CollectionServiceController } = gallery;

  return {
    provide: {
      api: {
        AuthServiceController,
        UserServiceController,
        StripeServiceController,
        PaypalServiceController,
        UploadServiceController,
        CollectionServiceController,
      },
    },
  };
});
