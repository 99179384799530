import validate from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/middleware/auth.js"),
  logout: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/middleware/logout.js"),
  nuxtserverinit: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/middleware/nuxtserverinit.js")
}