<template>
  <div>
    <NuxtLoadingIndicator />
    <div>
      <NuxtLayout />
    </div>
    <ModalsContainer />
    <GAlert v-model="showAlert" v-bind="alert" @close="showAlert=false" @confirm="onAlertConfirm" />
    <GConfirm v-model="showConfirm" v-bind="confirm" @close="onConfirmClose" @confirm="onConfirmClick" />
  </div>
</template>

<script>
import { ModalsContainer } from 'vue-final-modal';

export default {
  components: { ModalsContainer },
  data() {
    return {
      alert: {
        title: null,
        message: null,
        cta: 'Okay',
        onConfirm: () => null
      },
      confirm: {
        title: 'Are you sure?',
        message: null,
        cta: 'Confirm',
        onConfirm: () => null,
        onCancel: () => null,
        onClose: () => null,
      },
      showAlert: false,
      showConfirm: false,
    };
  },
  mounted() {
    this.$mitt.on('alert', this.onAlert);
    this.$mitt.on('confirm', this.onConfirm);
  },
  beforeUnmount() {
    this.$mitt.off('alert', this.onAlert);
    this.$mitt.off('confirm', this.onConfirm);
  },
  methods: {
    onConfirm(v) {
      this.confirm = v;
      this.showConfirm = true;
    },
    onAlert(v) {
      this.alert = v;
      this.showAlert = true;
    },
    onAlertConfirm() {
      if (this.alert.onConfirm) {
        try {
          this.alert.onConfirm();
        } catch {};
      }
      this.showAlert = false;
    },
    onConfirmClick() {
      if (this.confirm.onConfirm) {
        try {
          this.confirm.onConfirm();
        } catch {};
      }
      this.showConfirm = false;
    },
    onConfirmClose() {
      if (this.confirm.onClose) {
        try {
          this.confirm.onClose();
        } catch {};
      }
      this.showConfirm = false;
    }
  }
};
</script>
