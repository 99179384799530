export default defineAppConfig({
  theme: {
    dark: true,
  },
  ui: {
    safelistColors: ['orange', 'gray'],
    primary: 'orange',
    gray: 'zinc',
    // formGroup: {
    //   label: {
    //     wrapper: 'flex content-center items-center justify-between text-gray-100',
    //     'base': 'block font-medium text-gray-100',
    //   }
    // },
    // input: {
    //   // base: 'w-full border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6',
    //   variant: {
    //     outline: 'border-0 bg-white/5 dark:bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6',
    //     none: 'bg-transparent focus:ring-0 focus:shadow-none dark:bg-white/5',
    //   },
    //   color: {
    //     white: {
    //       none: 'border-0 bg-white/5 dark:bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6',
    //       outline: 'border-0 bg-white/5 dark:bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6'
    //     },
    //     gray: {
    //       none: 'border-0 bg-white/5 dark:bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6',
    //       outline: 'border-0 bg-white/5 dark:bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6'
    //     },
    //   },
    //   'icon': {
    //     'base': 'flex-shrink-0 text-gray-300 dark:text-gray-300',
    //     'color': 'text-{color}-500 dark:text-{color}-400',
    //     default: {
    //       color: 'gray'
    //     }
    //   },
    // }
  }
})