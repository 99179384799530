import { defineStore } from 'pinia';
import * as lzString from 'lz-string';

import { useUserStore } from './user';
 
const l = lzString.default || lzString;

const { compressToEncodedURIComponent, decompressFromEncodedURIComponent } = l;

export const useAuthStore = defineStore('auth', {
  id: 'auth',
  persist: {
    debug: true,
    serializer: {
      deserialize: e => JSON.parse(decompressFromEncodedURIComponent(e)),
      serialize: e => compressToEncodedURIComponent(JSON.stringify(e))
    }
  },

  state: () => {
    return {
      _token: null,
      _refresh: null,
      _verified: true,
    }
  },
  
  getters: {
    isAuthed({ _token }) {
      return !!_token;
    },
    getToken({ _token }) {
      return _token;
    },
    getRefresh({ _refresh }) {
      return _refresh;
    },
    getVerified({ _verified }) {
      return _verified;
    },
    getSession({ _token, _refresh }) {
      return { token: _token, refresh: _refresh };
    }
  },

  actions: {
    async AuthValidateCurrentSession(req = {}) {
      if (!req?.session) {
        console.warn('AuthValidateCurrentSession', 'bad request');
        return Promise.resolve(false);
      }
      
      const r = await this.GetAuthCheckSessionToken({ token: req.session.token, refresh: req.refresh.token });
      if (r.error) {
        this.SetSession({
          token: null,
          refresh: null,
          verified: false,
        })
        return Promise.resolve(false);
      }
      const { state } = r;
      const { session } = state;
      if (session?.verified && session?.session) {
        this.SetSession({
          token: session.session.token,
          refresh: session.refresh.token,
          verified: true,
        })
      }
      return Promise.resolve(true);
    },

    SetToken(token) {
      this._token = token;
    },
    
    SetSession(req = {}) {
      const { token = null, refresh = null, verified = false } = req || {};
      this._token = token;
      this._refresh = refresh;
      this._verified = verified;
    },

    SetVerified(verified = false) {
      this._verified = verified;
    },

    HandleStateUpdate(res) {
      const state = res.state || res || {};
      const { session } = state || {};
      if (session) {
        this.SetSession({
          token: session.session.token,
          refresh: session.refresh.token,
          verified: session.verified,
        });
      }
    },

    GetAuthCheckSessionToken({ token, refresh }) {
      const $config = useRuntimeConfig();
      const req = new this.$api.AuthServiceController({ ...$config.public, store: this, client: true });
      return req.GetAuthCheckSessionToken({ token, refresh }).catch(error => ({ error }));
    },

    GetAuthYolo(credential) {
      const $config = useRuntimeConfig();
      const req = new this.$api.AuthServiceController({ ...$config.public, token: this.token, store: this, client: true });
      return req.GetAuthYolo({ credential, utm: {}, meta: {}, version: 2 }).then((res) => {
        if (res.state.session.verified) {
          // this will set the cookie as well
          this.HandleStateUpdate(res);
        } else {
          this.SetVerified(false);
        }

        const u = useUserStore();

        u.SetUser(res.user);
        
        return res;
      }).catch(error => ({ error }));
    },

    GetAuthLogin({ username, password }) {
      const $config = useRuntimeConfig();
      const req = new this.$api.AuthServiceController({ ...$config.public, store: this, client: true });
      return req.GetAuthLogin({ email: username, password, version: 2 }).then((res) => {
        if (res.state.session.verified) {
          // this will set the cookie as well
          this.HandleStateUpdate(res);
        } else {
          this.SetVerified(false);
        }

        const u = useUserStore();

        u.SetUser(res.user);
        
        return res;
      }).catch(error => ({ error }))
    },

    GetAuthCodeConfirm({ code, token, scope = null }) {
      const $config = useRuntimeConfig();
      const req = new this.$api.AuthServiceController({ ...$config.public, token, store: this, client: true });
      return req.GetAuthCodeConfirm({ code, scope }).then((res) => {
        if (res.state.session.verified) {
          // this will set the cookie as well
          this.HandleStateUpdate(res);
        }
        
        return res;
      }).catch(error => ({ error }));
    },

    GetAuthTwoFactor({ code, token, scope = null }) {
      const $config = useRuntimeConfig();
      const req = new this.$api.AuthServiceController({ ...$config.public, token, store: this, client: true });
      return req.GetAuthTwoFactor({ code, scope }).catch(error => ({ error }));
    },

    GetAuthForgotPasswordV2(email) {
      const $config = useRuntimeConfig();
      const req = new this.$api.AuthServiceController({ ...$config.public, store: this, client: true });
      return req.GetAuthForgotPasswordV2({ email }).catch(error => ({ error }));
    },

    GetAuthConfirmPassword({ email, code }) {
      const $config = useRuntimeConfig();
      const req = new this.$api.AuthServiceController({ ...$config.public, store: this, client: true });
      return req.GetAuthConfirmPassword({ email, code }).catch(error => ({ error }));
    },

    GetAuthResetPassword(password) {
      const token = this.getAuthToken();
      const $config = useRuntimeConfig();
      const req = new this.$api.AuthServiceController({ ...$config.public, token, store: this, client: true });
      return req.GetAuthResetPassword({ password }).catch(error => ({ error }));
    },

    GetAuthRegister({ firstName, email, password, business = false }) {
      const meta = {
        site: 'gallery',
      }
      const $config = useRuntimeConfig();
      const req = new this.$api.AuthServiceController({ ...$config.public, store: this, client: true });
      return req.GetAuthRegister({ firstName, email, password, meta, business, version: 2 }).catch(error => ({ error }));
    },

    GetAuthCodeResend(token, scope = null) {
      const $config = useRuntimeConfig();
      const req = new this.$api.AuthServiceController({ ...$config.public, token, store: this, client: true });
      return req.GetAuthCodeResend({ scope }).catch(error => ({ error }));
    },

    async Logout() {
      const token = this.getAuthToken();
      const $config = useRuntimeConfig();
      const req = new this.$api.AuthServiceController({ ...$config.public, token, store: this, client: true });
      await req.GetAuthLogout().catch(error => ({ error }));
      this.SetSession({
        token: null,
        refresh: null,
        verified: false,
      })
      return Promise.resolve();
    }
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}