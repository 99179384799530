export default defineNuxtPlugin(async () => {
  const compress = await import(
    /* webpackChunkName: "plugin" */
    /* webpackMode: "lazy" */
    'compressorjs').then(e => e.default || e);

  return {
    provide: {
      compress
    }
  }
});