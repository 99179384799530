<template>
  <UModal v-model="isOpen" prevent-close class="select-none">
    <UCard :ui="{ ring: '', divide: 'divide-y divide-gray-100 dark:divide-gray-800/50' }">
      <template #header>
        <div class="flex items-center justify-between">
          <h3 class="text-lg font-semibold leading-6 text-gray-900 dark:text-white">
            {{ title || 'Are you sure?' }}
          </h3>
          <UButton
            v-if="showClose"
            color="gray"
            variant="ghost"
            icon="i-heroicons-x-mark-20-solid"
            class="-my-1"
            :class="[busy && 'pointer-events-none opacity-50']"
            @click="onClose"
          />
        </div>
      </template>

      <div>
        {{ message }}
      </div>
      <template #footer>
        <UButton
          size="md"
          class="flex flex-1 font-semibold text-center justify-center" 
          block
          :class="[busy && 'pointer-events-none']"
          :loading="busy"
          @click="onConfirm"
        >
          {{ cta || 'Okay' }}
        </UButton>
      </template>
    </UCard>
  </UModal>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: () => false,
    },
    busy: {
      type: Boolean,
      default: () => false,
    },
    showClose: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => 'Are you sure?',
    },
    message: {
      type: String,
      default: () => '',
    },
    cta: {
      type: String,
      default: () => 'Okay',
    }
  },
  emits: ['update:modelValue', 'confirm', 'cancel'],
  data() {
    return {
      isOpen: this.modelValue,
    }
  },
  watch: {
    isOpen(v) {
      this.$emit('update:modelValue', !!v);
    },
    modelValue(v) {
      this.isOpen = v;
    }
  },
  methods: {
    onClose() {
      this.$emit('cancel', true);
      this.$emit('update:modelValue', false);
    },
    onConfirm() {
      this.$emit('confirm', true);
      // this.$emit('update:modelValue', false);
    }
  }
}
</script>