import { useAuthStore } from '~/stores/auth.js';
import { useUserStore } from '~/stores/user.js';
import { useBaseStore } from '~/stores/base.js';

function MyPiniaPlugin() {
  const auth = useAuthStore();
  const $config = useRuntimeConfig();
  const { $api } = useNuxtApp();

  return {
    getUserId: () => {
      return auth.userId;
    },
    getUser: () => {
      return auth.user;
    },
    getAuthToken: () => {
      return auth.getToken;
    },
    $config: $config.public,
    $api,
  }
}

export default defineNuxtPlugin(({ $pinia }) => {
  $pinia.use(MyPiniaPlugin);
  return {
    provide: {
      store: {
        auth: useAuthStore(),
        user: useUserStore(),
        base: useBaseStore(),
      },
    },
  };
});
