export default defineNuxtPlugin(async () => {
  const toast = await import(
    /* webpackChunkName: "plugin" */
    /* webpackMode: "lazy" */
    'toastify-js').then((e) => e.default || e);

  return {
    provide: {
      toast
    }
  }
});